import { getInputProps } from '@conform-to/react'
import { type FormProps } from '@remix-run/react'
import { z } from 'zod'
import { Input } from '../../../../components/ui/radixUiTheme/Input'
import {
	FirstNameSchema,
	LastNameSchema,
	PasswordAndConfirmPasswordSchema,
	PhoneNumberSchema,
	UsernameSchema,
} from '../../../../utils/user-validation'
import { UserImageUpload } from '../_components/ImageUpload'

// const MAX_SIZE_USER_IMG = 1024 * 1024 * 3

export const Step1UserSignUpSchema = z
	.object({
		username: UsernameSchema,
		firstName: FirstNameSchema,
		lastName: LastNameSchema,
		phoneNumber: PhoneNumberSchema,
		userImage: z
			.instanceof(File, { message: 'This should be a valid file' })
			.optional(),
		// .refine(file => file && file.size > 0, 'Image is required')
		// .refine(
		// 	file => file && file.size <= MAX_SIZE_USER_IMG,
		// 	'Image size must be less than 3MB',
		// ),
	})
	.and(PasswordAndConfirmPasswordSchema)

export function Step1({
	form,
	fields,
	setFirstName,
	setLastName,
	setStep,
}: {
	form: FormProps
	fields: any
	setFirstName: (firstName: string) => void
	setLastName: (lastName: string) => void
	setStep: (step: number) => void
}) {
	return (
		<div>
			<UserImageUpload form={form} fields={fields} />

			<Input
				{...getInputProps(fields.username, { type: 'text' })}
				type="text"
				name="username"
				autoComplete="username"
				labelText="Username"
				placeholder="Enter your username..."
				error={fields.username.errors?.[0]}
			/>

			<div
				data-testid="step-1-full-name-container"
				className="flex flex-col sm:flex-row sm:gap-2"
			>
				<Input
					{...getInputProps(fields.firstName, { type: 'text' })}
					name="firstName"
					autoComplete="firstName"
					required={true}
					labelText="First Name"
					placeholder="Enter your first name..."
					error={fields.firstName.errors?.[0]}
					onChange={e => {
						setFirstName(e.target.value)
					}}
				/>
				<Input
					{...getInputProps(fields.lastName, { type: 'text' })}
					name="lastName"
					autoComplete="lastName"
					required={true}
					labelText="Last Name"
					placeholder="Enter your last name..."
					error={fields.lastName.errors?.[0]}
					onChange={e => {
						setLastName(e.target.value)
					}}
				/>
			</div>
			<div
				data-testid="step-1-password-container"
				className="flex flex-col sm:flex-row sm:gap-2"
			>
				<Input
					type="password"
					name="password"
					required={true}
					autoComplete="new-password"
					labelText="Password"
					placeholder="Enter your password..."
					error={fields.password.errors?.[0]}
				/>
				<Input
					type="password"
					name="confirmPassword"
					required={true}
					autoComplete="new-password"
					labelText="Confirm Password"
					placeholder="Confirm your password..."
					error={fields.confirmPassword.errors?.[0]}
				/>
			</div>
		</div>
	)
}

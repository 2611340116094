import {
	getFormProps,
	getInputProps,
	type SubmissionResult,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { invariant, invariantResponse } from '@epic-web/invariant'
import {
	json,
	redirect,
	type LoaderFunctionArgs,
	type ActionFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import {
	Form,
	useActionData,
	useLoaderData,
	useSearchParams,
} from '@remix-run/react'
import { useEffect, useMemo, useState } from 'react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { safeRedirect } from 'remix-utils/safe-redirect'
import { z } from 'zod'
import { ErrorList } from '#app/components/forms.tsx'
import { requireAnonymous, sessionKey, signup } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { authSessionStorage } from '#app/utils/session.server.ts'
import { redirectWithToast } from '#app/utils/toast.server.ts'
import { verifySessionStorage } from '#app/utils/verification.server.ts'
import { APP_NAME } from '../../../constants.ts'
import { sendEmail } from '../../../utils/email.server.ts'
import { getSchemaKeys } from '../../../utils/zod.utils'
import { OuterForm } from '../_components/_outerForm.tsx'
import { WelcomeEmail } from '../_emails.tsx'
import { type VerifyFunctionArgs } from '../verify.tsx'
import { StepNavButtons } from './_components/StepNavButtons'
import { StepStepper } from './_components/StepStepper.tsx'
import { Step1, Step1UserSignUpSchema } from './_onboardingSteps/Step1.tsx'
import { Step2, Step2BusinessSignUpSchema } from './_onboardingSteps/Step2.tsx'
import { Step3SocialsSignUpSchema, Step3 } from './_onboardingSteps/Step3.tsx'
import { StepFinal, StepFinalSchema } from './_onboardingSteps/StepFinal'

const onboardingEmailSessionKey = 'onboardingEmail'

const SignupFormSchema = z
	.object({
		// intent: z.literal('submit'),
		remember: z.boolean().optional(),
		redirectTo: z.string().optional(),
	})
	.and(Step1UserSignUpSchema)
	.and(Step2BusinessSignUpSchema)
	.and(Step3SocialsSignUpSchema)
	.and(StepFinalSchema)
/**
 * This function is used to require the onboarding email from the user.
 * It will throw a redirect to the signup page if the email is not found.
 * @param request - The request object
 * @returns The onboarding email
 */
async function requireOnboardingEmail(request: Request) {
	await requireAnonymous(request)
	const verifySession = await verifySessionStorage.getSession(
		request.headers.get('cookie'),
	)

	const email = verifySession.get(onboardingEmailSessionKey)

	console.log('Email from requireOnboardingEmail', email)

	if (typeof email !== 'string' || !email) {
		await redirectWithToast('/signup', {
			title: 'Signup',
			description: 'Please signup to continue',
		})
		// redirect('/signup')
	}
	return email
}

// async function verifyRecaptcha(token: string, remoteIp?: string) {
// 	const secretKey = process.env.GOOGLE_RECAPTCHA_SECRET_KEY

// 	const response: Response = await fetch(
// 		'https://www.google.com/recaptcha/api/siteverify',
// 		{
// 			method: 'POST',
// 			headers: {
// 				'Content-Type': 'application/x-www-form-urlencoded',
// 			},
// 			body: new URLSearchParams({
// 				secret: secretKey || '',
// 				response: token,
// 				remoteip: remoteIp || '',
// 			}).toString(),
// 		},
// 	)

// 	return response

// 	// type RecaptchaResponse = {
// 	// 	success: boolean
// 	// }
// 	// const data = (await response.json()) as RecaptchaResponse
// 	// return data.success
// }

export async function handleVerification({ submission }: VerifyFunctionArgs) {
	invariant(
		submission.status === 'success',
		'Submission should be successful by now',
	)
	const verifySession = await verifySessionStorage.getSession()
	verifySession.set(onboardingEmailSessionKey, submission.value.target)
	return redirect('/onboarding', {
		headers: {
			'set-cookie': await verifySessionStorage.commitSession(verifySession),
		},
	})
}

export async function loader({ request }: LoaderFunctionArgs) {
	const email = await requireOnboardingEmail(request)
	const captchaToken = process.env.GOOGLE_RECAPTCHA_SITE_KEY

	invariantResponse(captchaToken, 'Captcha token is required')

	return json({ email, captchaToken })
}

export async function action({ request }: ActionFunctionArgs) {
	const email = await requireOnboardingEmail(request)
	const formData = await request.formData()
	checkHoneypot(formData)

	// const captchaToken = formData.get('recaptcha-token') as string
	// const remoteIp = request.headers.get('x-forwarded-for') || '' // fallback as needed
	// console.log('Captcha token', captchaToken)
	// console.log('Remote IP', remoteIp)
	// const captchaResponse = await verifyRecaptcha(captchaToken, remoteIp)
	// console.log('Captcha response', captchaResponse)
	// if (!captchaToken || captchaResponse.ok !== true) {
	// 	return json(
	// 		{
	// 			result: { errors: [captchaResponse.statusText] },
	// 		},
	// 		{ status: 400 },
	// 	)
	// }

	const submission = await parseWithZod(formData, {
		schema: intent =>
			SignupFormSchema.superRefine(async (data, ctx) => {
				const existingUsername = await prisma.user.findFirst({
					where: {
						username: data.username,
					},
					select: { id: true },
				})

				if (existingUsername) {
					ctx.addIssue({
						path: ['username'],
						code: z.ZodIssueCode.custom,
						message: 'A user already exists with this username',
					})
				}
			}).transform(async data => {
				if (intent !== null) return { ...data, session: null }

				const session = await signup({ ...data, email })
				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply() },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	const authSession = await authSessionStorage.getSession(
		request.headers.get('cookie'),
	)
	authSession.set(sessionKey, session.id)
	const verifySession = await verifySessionStorage.getSession()
	const headers = new Headers()
	headers.append(
		'set-cookie',
		await authSessionStorage.commitSession(authSession, {
			expires: remember ? session.expirationDate : undefined,
		}),
	)
	headers.append(
		'set-cookie',
		await verifySessionStorage.destroySession(verifySession),
	)

	const response = await sendEmail({
		to: email,
		subject: `Welcome to ${APP_NAME} - Let's Grow Your Business! 🚀`,
		react: (
			<WelcomeEmail
				firstName={formData.get('firstName')?.toString() ?? ''}
				lastName={formData.get('lastName')?.toString() ?? ''}
				username={formData.get('username')?.toString() ?? ''}
			/>
		),
	})

	if (response.status === 'success') {
		return redirectWithToast(
			safeRedirect(redirectTo),
			{ title: 'Welcome', description: 'Thanks for signing up!' },
			{ headers },
		)
	} else {
		return json(
			{
				result: submission.reply({ formErrors: [response.error.message] }),
			},
			{
				status: 500,
			},
		)
	}
}

export default function SignupRoute() {
	const data = useLoaderData<typeof loader>()
	const actionData = useActionData<typeof action>()

	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	// Literally just for the nice Header
	const [headingName, setHeadingName] = useState(data.email)
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	useEffect(() => {
		const newFullName = [firstName, lastName].filter(Boolean).join(' ')
		setHeadingName(newFullName || data.email)
	}, [firstName, lastName, data.email])

	const [form, fields] = useForm({
		id: 'onboarding-form',
		constraint: getZodConstraint(SignupFormSchema),
		defaultValue: { redirectTo, agreeToTermsOfServiceAndPrivacyPolicy: false },
		lastResult: actionData?.result as SubmissionResult<string[]> | undefined,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: SignupFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	const [step, setStep] = useState(1)

	const handleStepChange = (nextStep: number) => {
		setStep(nextStep)
	}

	const stepComponents: Record<
		number,
		{ subheading: string; schemaKeys: string[] }
	> = useMemo(() => {
		return {
			1: {
				subheading: 'Start by telling us a bit about you, the owner.',
				schemaKeys: getSchemaKeys(Step1UserSignUpSchema),
			},
			2: {
				subheading: 'Tell us a bit about your business.',
				schemaKeys: getSchemaKeys(Step2BusinessSignUpSchema),
			},
			3: {
				subheading: 'Add some of your social media links (if you want).',
				schemaKeys: getSchemaKeys(Step3SocialsSignUpSchema),
			},
			4: {
				subheading: 'Final housekeeping steps.',
				schemaKeys: getSchemaKeys(StepFinalSchema),
			},
		}
	}, [])

	const errorSteps = useMemo(() => {
		return Object.entries(stepComponents).reduce(
			(acc, [step, { schemaKeys }]) => {
				const hasError = schemaKeys.some(key => {
					const fieldErrors = fields[key as keyof typeof fields]?.errors
					return fieldErrors && fieldErrors.length > 0
				})
				if (hasError) {
					acc.push(Number(step))
				}
				return acc
			},
			[] as number[],
		)
	}, [fields, stepComponents])

	return (
		<OuterForm
			isOnboardingForm={true}
			headingChildren={
				<>
					<span className="block">Welcome aboard, </span>
					<span className="block">{headingName}!</span>
				</>
			}
			subHeadingChildren={stepComponents[step].subheading}
			formChildren={
				<Form
					{...getFormProps(form)}
					method="POST"
					className="flex flex-1 flex-col"
					encType="multipart/form-data"
					// onSubmit={e => {
					// 	const formData = new FormData(e.currentTarget)
					// 	for (const [key, value] of formData.entries()) {
					// 		console.log(`${key}: ${value}`)
					// 	}
					// }}
				>
					<HoneypotInputs />

					<StepStepper
						currStep={step}
						totalSteps={4}
						setStep={handleStepChange}
						errorSteps={errorSteps}
					/>

					<div style={{ display: step === 1 ? 'block' : 'none' }}>
						<Step1
							form={form}
							fields={fields}
							setFirstName={setFirstName}
							setLastName={setLastName}
							setStep={handleStepChange}
						/>
					</div>
					<div style={{ display: step === 2 ? 'block' : 'none' }}>
						<Step2 form={form} fields={fields} setStep={handleStepChange} />
					</div>
					<div style={{ display: step === 3 ? 'block' : 'none' }}>
						<Step3 fields={fields} setStep={handleStepChange} />
					</div>
					<div style={{ display: step === 4 ? 'block' : 'none' }}>
						<StepFinal
							form={form}
							fields={fields}
							setStep={handleStepChange}
							serverCaptchaToken={data.captchaToken}
						/>
					</div>

					<input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
					<ErrorList errors={form.errors} id={form.errorId} />

					<StepNavButtons currStep={step} setStep={setStep} totalSteps={4} />
				</Form>
			}
		/>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: `Setup ${APP_NAME} Account` }]
}

import { type ZodTypeAny, ZodObject, ZodIntersection, ZodEffects } from 'zod'

export function getSchemaKeys(schema: ZodTypeAny): string[] {
	// Unwrap effects/refinements if present
	if (schema instanceof ZodEffects) {
		schema = schema._def.schema
	}

	// If the schema is an intersection, recursively flatten the keys.
	if (schema instanceof ZodIntersection) {
		const leftKeys = getSchemaKeys(schema._def.left)
		const rightKeys = getSchemaKeys(schema._def.right)
		return [...leftKeys, ...rightKeys]
	}
	// If it's a plain object, return its keys.
	if (schema instanceof ZodObject) {
		return Object.keys(schema.shape)
	}
	return []
}

import { getInputProps } from '@conform-to/react'
import { z } from 'zod'
import { Icon } from '../../../../components/ui/icon'
import { Input, InputSlot } from '../../../../components/ui/radixUiTheme/Input'

export const Step3SocialsSignUpSchema = z.object({
	facebook: z.string().optional(),
	twitter: z.string().optional(),
	linkedIn: z.string().optional(),
	instagram: z.string().optional(),
	youtube: z.string().optional(),
	website: z.string().optional(),
})

export function Step3({
	fields,
	setStep,
}: {
	fields: any
	setStep: (step: number) => void
}) {
	return (
		<div className="flex flex-col gap-2">
			<Input
				{...getInputProps(fields.facebook, { type: 'text' })}
				name="facebook"
				autoComplete="facebook"
				labelText="Facebook"
				placeholder="Enter your Facebook URL..."
			>
				<InputSlot>
					<Icon name="logo-facebook" />
				</InputSlot>
			</Input>

			<Input
				{...getInputProps(fields.twitter, { type: 'text' })}
				name="twitter"
				autoComplete="twitter"
				labelText="X"
				placeholder="Enter your X URL..."
			>
				<InputSlot>
					<Icon name="logo-x" />
				</InputSlot>
			</Input>

			<Input
				{...getInputProps(fields.linkedIn, { type: 'text' })}
				name="linkedIn"
				autoComplete="linkedIn"
				labelText="LinkedIn"
				placeholder="Enter your LinkedIn URL..."
			>
				<InputSlot>
					<Icon name="logo-linkedIn" />
				</InputSlot>
			</Input>

			<Input
				{...getInputProps(fields.instagram, { type: 'text' })}
				name="instagram"
				autoComplete="instagram"
				labelText="Instagram"
				placeholder="Enter your Instagram URL..."
			>
				<InputSlot>
					<Icon name="logo-instagram" />
				</InputSlot>
			</Input>

			<Input
				{...getInputProps(fields.youtube, { type: 'text' })}
				name="youtube"
				autoComplete="youtube"
				labelText="YouTube"
				placeholder="Enter your YouTube URL..."
			>
				<InputSlot>
					<Icon name="logo-youtube" />
				</InputSlot>
			</Input>

			<Input
				{...getInputProps(fields.website, { type: 'text' })}
				name="website"
				autoComplete="website"
				labelText="Website"
				placeholder="Enter your website URL..."
			>
				<InputSlot>
					<Icon name="globe" />
				</InputSlot>
			</Input>
		</div>
	)
}

import { Button } from '../../../../components/ui/radixUiTheme/Button'
import { useIsPending } from '../../../../utils/misc'

export function StepNavButtons({
	currStep,
	totalSteps,
	setStep,
}: {
	currStep: number
	totalSteps: number
	setStep: (step: number) => void
}) {
	const isPending = useIsPending()

	return (
		<div className="flex w-full flex-row gap-2">
			{currStep > 1 && (
				<Button
					className="flex flex-1"
					type="button"
					variant="outline"
					onClick={() => setStep(currStep - 1)}
				>
					Previous
				</Button>
			)}
			{currStep < totalSteps ? (
				<Button
					className="flex flex-1"
					type="button"
					onClick={() => setStep(currStep + 1)}
				>
					Next
				</Button>
			) : (
				<Button
					className="flex flex-1"
					type="submit"
					disabled={isPending}
					name="intent"
					value="submit"
				>
					Create an Account
				</Button>
			)}
		</div>
	)
}

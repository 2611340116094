import { getInputProps } from '@conform-to/react'
import { type FormProps } from '@remix-run/react'
import { z } from 'zod'
import { Input } from '../../../../components/ui/radixUiTheme/Input'
import { BusinessImageUpload } from '../_components/ImageUpload'

// const MAX_SIZE_BUSINESS_IMG = 1024 * 1024 * 3 //3mb

const businessNameSchema = z
	.string({ required_error: 'Business name is required' })
	.trim()
	.min(1, 'Business name should be at least 1 character')

const einSchema = z
	.string({ required_error: 'EIN is required' })
	.trim()
	.regex(
		/^\d{2}-?\d{7}$/,
		'EIN must be 9 digits in XX-XXXXXXX or XXXXXXXXX format',
	)
	.transform(val => val.replace('-', ''))

export const Step2BusinessSignUpSchema = z.object({
	businessName: businessNameSchema,
	ein: einSchema,
	businessImage: z
		.instanceof(File, { message: 'This should be a valid file' })
		.optional(),
	// .refine(file => file.size > 0, 'Image is required')
	// .refine(
	// 	file => file && file.size <= MAX_SIZE_BUSINESS_IMG,
	// 	'Image size must be less than 3MB',
	// ),
})

export function Step2({
	form,
	fields,
	setStep,
}: {
	form: FormProps
	fields: any
	setStep: (step: number) => void
}) {
	return (
		<div className="flex flex-col gap-2">
			<BusinessImageUpload form={form} fields={fields} />

			<Input
				{...getInputProps(fields.businessName, { type: 'text' })}
				name="businessName"
				autoComplete="businessName"
				labelText="Business Name"
				placeholder="Enter your business name..."
				error={fields.businessName.errors?.[0]}
			/>

			<Input
				{...getInputProps(fields.ein, { type: 'text' })}
				name="ein"
				autoComplete="ein"
				labelText="EIN"
				placeholder="Enter your EIN..."
				error={fields.ein.errors?.[0]}
			/>
		</div>
	)
}

import { type FormProps } from '@remix-run/react'
// import { useState } from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import { z } from 'zod'
// import { useTheme } from '../../../../components/sideBarNavigation/themeSwitch'
// import { Button } from '../../../../components/ui/radixUiTheme/Button'
import { CheckboxField } from '../../../../components/ui/radixUiTheme/Checkbox'

export const StepFinalSchema = z.object({
	agreeToTermsOfServiceAndPrivacyPolicy: z.boolean({
		required_error: 'You must agree to the terms of service and privacy policy',
	}),
	// 'recaptcha-token': z
	// 	.string({
	// 		required_error: 'You must solve the captcha',
	// 	})
	// 	.nonempty('You must solve the captcha'),
})

export function StepFinal({
	form,
	fields,
	setStep,
	serverCaptchaToken,
}: {
	form: FormProps & { id: string }
	fields: any
	setStep: (step: number) => void
	serverCaptchaToken: string
}) {
	// const [captchaToken, setCaptchaToken] = useState<string | null>(null)

	// const theme = useTheme()

	return (
		<div>
			{/* <div className="flex w-full flex-row items-center justify-center border-2">
				<ReCAPTCHA
					sitekey={serverCaptchaToken}
					onChange={(token: string | null) => setCaptchaToken(token)}
					onExpired={() => setCaptchaToken(null)}
					theme={theme}
					// className="[&>div>div>iframe]:w-[400px] [&>iframe>html]:bg-background"
					style={{ background: 'transparent' }}
					onError={() => {
						console.log('error')
					}}
				/>
				<input type="hidden" name="captchaToken" value={captchaToken || ''} />
			</div> */}

			<CheckboxField
				buttonProps={{
					form: form.id,
					name: fields.agreeToTermsOfServiceAndPrivacyPolicy.name,
					id: fields.agreeToTermsOfServiceAndPrivacyPolicy.id,
				}}
				labelProps={{
					htmlFor: fields.agreeToTermsOfServiceAndPrivacyPolicy.id,
					children: 'Do you agree to our Terms of Service and Privacy Policy?',
				}}
				errors={fields.agreeToTermsOfServiceAndPrivacyPolicy.errors}
			/>
		</div>
	)
}

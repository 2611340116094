import { Icon } from '../../../../components/ui/icon'
import { Button } from '../../../../components/ui/radixUiTheme/Button'
import { Separator } from '../../../../components/ui/radixUiTheme/Separator'

export function StepStepper({
	currStep,
	totalSteps,
	setStep,
	errorSteps,
}: {
	currStep: number
	totalSteps: number
	setStep: (step: number) => void
	errorSteps?: number[]
}) {
	return (
		<div className="my-6 flex w-full flex-row justify-between">
			{Array.from({ length: totalSteps }).map((_, index) => {
				const stepNumber = index + 1

				const isErrorStep = errorSteps?.includes(stepNumber)
				const isCurrentStep = stepNumber === currStep

				return (
					<div
						key={index}
						className="flex flex-1 flex-row items-center justify-center"
					>
						{index > 0 && (
							<Separator className="flex-1" orientation="horizontal" />
						)}
						<Button
							key={index}
							type="button"
							variant={isCurrentStep ? 'solid' : 'outline'}
							onClick={() => setStep(stepNumber)}
						>
							{stepNumber}

							{isErrorStep && <Icon name="exclamation-triangle" />}
						</Button>
						{index < totalSteps - 1 && (
							<Separator className="flex-1" orientation="horizontal" />
						)}
					</div>
				)
			})}
		</div>
	)
}

import { type FormProps } from '@remix-run/react'
import { useState } from 'react'
import { Icon } from '../../../../components/ui/icon'
import { IconButton } from '../../../../components/ui/radixUiTheme/IconButton'

export function UserImageUpload({
	form,
	fields,
}: {
	form: FormProps
	fields: any
}) {
	return <ImageUploadCircle imgFieldInfo={fields.userImage} />
}

export function BusinessImageUpload({
	form,
	fields,
}: {
	form: FormProps
	fields: any
}) {
	return <ImageUploadCircle imgFieldInfo={fields.businessImage} />
}

export function ImageUploadCircle({ imgFieldInfo }: { imgFieldInfo: any }) {
	const [newImageSrc, setNewImageSrc] = useState<string | null>(null)

	function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const file = event.target.files?.[0]
		if (file) {
			const reader = new FileReader()
			reader.onloadend = () => {
				setNewImageSrc(reader.result as string)
			}
			reader.readAsDataURL(file)
		}
	}

	return (
		<div className="flex w-full flex-col items-center justify-center">
			<div className="relative h-52 w-52">
				<div className="h-full w-full overflow-hidden rounded-full border-2">
					{newImageSrc ? (
						<img
							src={newImageSrc}
							alt="Profile preview"
							className="h-full w-full object-cover"
						/>
					) : (
						<div className="flex h-full w-full items-center justify-center bg-muted">
							<Icon name="avatar" className="h-32 w-32" />
						</div>
					)}
				</div>
				<label htmlFor={imgFieldInfo.id}>
					<IconButton
						type="button"
						className="absolute right-0 top-0 flex items-center justify-center rounded-full"
						onClick={() => {
							const input = document.getElementById(
								imgFieldInfo.id,
							) as HTMLInputElement
							input.click()
						}}
					>
						<Icon name="camera" />
					</IconButton>
				</label>
				<input
					id={imgFieldInfo.id}
					name={imgFieldInfo.name}
					type="file"
					accept="image/*"
					className="hidden"
					onChange={handleFileChange}
					aria-label="User profile photo"
				/>
			</div>
			{imgFieldInfo.errors ? (
				<div className="text-sm text-destructive">{imgFieldInfo.errors}</div>
			) : null}
		</div>
	)
}
